header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 40px;
}

ul{
    list-style: none;
    display: flex;

}

li{
    padding-right: 90px;
    color: #fff;
    font-weight: 400;
}

li:hover{
    cursor: pointer;
    color: #9be1fd;
}

a:hover {
    cursor: pointer;
    /* color: #9be1fd; */
}

button:hover {
    cursor: pointer;
    background-color: #9be1fd;
}

.link{
    text-decoration: none;
}

.iconMenu{
    display: none;
}

.logo{
    width: 150px;
}


/* Responsividade */
@media only screen and (max-width: 1000px) {

    header{
        flex-wrap: wrap;
        justify-content: space-between;
        /* flex-direction: column; */
        padding-top: 1rem;
        padding-bottom: 7%;
        padding-right: 20px;
    }

    .logo{
        width: 150px;
    }

    .iconMenu{
        display: inline;
        margin-right: 8%;
    }

    .itensHeader{
        display: none;
    }

    .open{
        width: 100%;
        flex-direction: column;
        text-align: center;
        align-content: center;
        align-items: center;
        justify-content: center;
        margin-left: -3%;
        padding-top: 10%;
    }

    ul{
        flex-wrap: wrap;
        flex-direction: column;
        padding-left: 0%;
    }
    li, .link{
        padding-right: 0%;
        padding-top: 5%;
        padding-bottom: 5%;
    }
}