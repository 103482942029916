.iconServico{
    height: 75px;
    margin-right: 30px;

}

.cardServico{
    display: flex;
    margin-top: 20px;
}

.space-15 {
    line-height: 2rem;
    font-size: 1.2rem;
}

/* Responsividade */
@media only screen and (max-width: 1000px){

    .iconServico{
        width: 80px;
        height: auto;
        margin-bottom: 15px;
    }

    .cardServico{
        flex-direction: column;
        margin-bottom: 20px;
    }
}