.contato{
    background-image: url('../../images/background-contato.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 85px 0px 50px 50px;
}

.sContato{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}


/* .formContato{
    margin-top: 25px;
    width: 45%;
}

.d2Contato{
    padding-top: 35px;
}


form > input{
    display: block;
    width: 582px;
    height: 45px;
    margin-bottom: 15px;
    background-color: transparent;
    border: 1px solid;
    border-color: #D9D9D9;
    padding-left: 20px;
    color: #fff;

}

form > input::placeholder{
    color: #fff;
    font-size: var(--size-h5);
}

form > input:focus, textarea{
    border-radius: 0%;
    outline: 0;
}

textarea{
    background-color: transparent;
    border: 1px solid;
    border-color: #D9D9D9;
    padding-left: 20px;
    padding-top: 20px;
    color: #fff;
}

form > textarea::placeholder{
    color: #fff;
    font-size: var(--size-h5);
    font-family: 'Rubik', sans-serif;
} */


.address{
    display: flex;
    align-items: center;
    margin: 22px 0px;

}

.linkAddress{
    color: #fff;
    text-decoration: none;
    padding-left: 15px;
    /* font-size: var(--size-h5); */
    font-size: 1.5rem;
}


.icons-contact{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 35px 0px;
}

.icon-social-contact{
    background-color: var(--cor-primaria);
    padding: 10px 10px;
    margin: 0px 15px;
}

/* Responsividade */
@media only screen and (max-width: 1000px){

    .contato{
        padding: 85px 0px 50px 0px;
    }

    .icons-contact{
        justify-content: space-between;
    }

    .icon-social-contact{
        margin: 0px 8px;
    }

    .address{
        flex-direction: column;
        justify-content: space-around;
        text-align: center;
        height: 130px;
        width: 97%;
    }
}