.sobre{
    padding: 85px 0px 80px 50px;
    display: flex;
    justify-content: space-around;
}

.s1Sobre{
    width: 50%;
}

.space-15 {
    line-height: 2rem;
    font-size: 1.2rem;
}

/* Responsividade */
@media only screen and (max-width: 1000px){
    .sobre{
        padding: 85px 0px 80px 20px;
        flex-direction: column;
    }

    .s1Sobre{
        width: 97%;
    }

    .imgSobre{
        margin-top: 50px;
        width: 95%;
    }
}