.bannerServico{
    padding-top: 100px;
    padding-bottom: 25px;
    display: flex;
    
}

.textsBannerServico{
    width: 60%;
}

.imgBannerServico{
    width: 92%;
    margin-top: -10%;
}

.divTel{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 92%;
    padding-top: 1%;
    padding-bottom: 0.5%;
    background-color: #211F20;
    margin-top: 35px;
    margin-bottom: -180px;
}

.textTel{
    padding-top: 3.5%;
    padding-left: 3%;
}

/* Responsividade */
@media only screen and (max-width: 1000px) {
    
    .bannerServico{
        width: 97%;
        padding-top: 30px;
        padding-bottom: 20px;
    }

    .textsBannerServico{
        width: 92%;
    }

    

    .s2BannerServico{
        display: none;
    }
}