.cardInfo{
    margin-top: 20px;
    width: 300px;
    height: 200px;
    background-color: var(--cor-secundaria);
    padding: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.h5Branco {
    padding-left: 25px;
    padding-right: 25px;
    font-size: 1.2rem;
}