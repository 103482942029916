.sobreServicos{
    padding: 70px 10px 30px 35px;
}

.divConsultoria{
    display: flex;
    margin-bottom: 120px;
}

.divManutencao{
    display: flex;
    margin-bottom: 120px;
    justify-content: center;
    align-items: center;
}

.iconName{
    display: flex;
    margin-bottom: 25px;
}

.itensConsultoria{
    width: 55%;
    margin-right: 85px;
}

.imgConsultoria{
    height: 500px;
    margin-top: 30px;
}

.itensManutencao{
    width: 55%;
    margin-left: 55px;
    margin-right: 35px;
}

.imgManutencao{
    height: 400px;
}

.h4 {
    font-size: var(--size-h3);
    font-weight: 600;
    color: black;
    margin-top: 12px;
    margin-bottom: 2px;
}

.space-15 {
    line-height: 2rem;
    font-size: 1.2rem;
}


/* Responsividade */
@media only screen and (max-width: 1000px){
    .imgConsultoria, .imgManutencao{
        display: none;
    }

    .itensConsultoria{
        width: 92%;
        margin-right: 0px;
    }

    .itensManutencao{
        width: 92%;
        margin-left: -35px;
        margin-right: 0px;
    }

    .divConsultoria{
        margin-bottom: 60px;
    }

    .divManutencao{
        margin-bottom: 40px;
    }
}