.bannerSobre{
    padding-top: 100px;
    padding-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.textsBannerSobre{
    width: 60%;
}

.imgBannerSobre{
    width: 90%;
    margin-top: -10%;
}


/* Responsividade */
@media only screen and (max-width: 1000px){

    .textsBannerSobre{
        width: 92%;
    }

    .imgBannerSobre{
        display: none
    }
}