@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');


:root{
  --cor-primaria: #05A3E2;
  --cor-secundaria: #1E69A3;
  --cor-terciaria: #F3E003;

  --size-h1: 42px;
  --size-h2: 30px;
  --size-h3: 21px;
  --size-h4: 18px;
  --size-h5: 15px;

  --size-h1-mobile: 22px;
  --size-h2-mobile: 21px;
  --size-h3-mobile: 18px;
  --size-h4-mobile: 18px;
  --size-h5-mobile: 15px;
}

body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

html{
    scroll-behavior: smooth;
    scroll-padding-top: 60px;

}


/* Títulos */

h1{
  font-size: var(--size-h1);
  font-weight: 500;
  color: #fff;
}

.h2Branco{
  font-size: var(--size-h2);
  font-weight: 700;
  color: #fff;
}

.h2Preto{
  font-size: var(--size-h2);
  font-weight: 700;
  color: #000;
}


h3{
  font-size: var(--size-h3);
  color: #fff;
  font-weight: 500;
}


.h3Azul{
  font-size: var(--size-h3);
  color: var(--cor-primaria);
  font-weight: 600;
}

h4{
  font-size: var(--size-h4);
  font-weight: 600;
  color: #fff;
  margin-top: 0;
}

.h4Preto{
  font-size: var(--size-h4);
  font-weight: 400;
  color: #000;
  margin-top: 0;
}

.h5Branco{
  font-size: var(--size-h5);
  font-weight: 300;
  color: #fff;
  margin-top: 0;
  text-align: left;
}

.h5Branco500{
  font-size: var(--size-h5);
  font-weight: 500;
  color: #fff;
  margin-top: 0;
}

.h5Preto{
  font-size: var(--size-h5);
  font-weight: 300;
  color: #000;
  margin-top: 0;
}

.h5Preto400{
  font-size: var(--size-h4);
  line-height: 1.5rem;
  font-weight: 400;
  color: #000;
  margin-top: 0;
  margin-left: 4rem;
  margin-right: 4rem;
}

.h5Azul{
  font-size: var(--size-h5);
  font-weight: 500;
  color: var(--cor-primaria);
  margin-top: 0;
}


/* Botões */
button{
  color: #fff;
  font-size: var(--size-h5);
  border: none;
  padding: 12px 45px 12px 45px;
  font-weight: 600;
  margin-right: 10px;
}

.btn{
  color: #fff;
  font-size: var(--size-h5);
  border: none;
  padding: 12px 45px 12px 45px;
  font-weight: 600;
  margin-right: 10px;
  text-align: center;
}

.buttonAzul1{
  background-color: var(--cor-primaria);

}

.buttonAzul2{
  background-color: var(--cor-secundaria);
  color: #fff;
  width: 215px;
}

.buttonAmarelo{
  background-color: var(--cor-terciaria);
  color: #000;
  width: 215px;
}

.text-center {
  text-align: center;
}


/* Responsividade */
@media only screen and (max-width: 1000px) {
    /* Títulos */

    h1{
      font-size: var(--size-h1-mobile);
    }

    .h2Branco, .h2Preto{
      font-size: var(--size-h2-mobile);
    }

    h3{
      font-size: var(--size-h3-mobile);
    }

    h4, .h4Preto{
      font-size: var(--size-h4-mobile);
    }

    .h5Branco, .h5Branco500, .h5Preto, .h5Azul{
      font-size: var(--size-h5-mobile);
    }
    .h5Preto400{
    margin-left: 10px;
    margin-right: 10px;
    }
}