.backgroundS2{
    background-image: url('../../images/background-azul-escuro.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 85px 50px 50px 50px;
    display: flex;
    justify-content: space-around;
}

.s1Servico{
    width: 55%;
}

/* Responsividade */
@media only screen and (max-width: 1000px){
    .backgroundS2{
        flex-direction: column;
        padding: 85px 0px 70px 20px;
    }

    .s1Servico{
        width: 97%;
    }

    .textsServico{
        text-align: center;
    }

    .buttonAzul1{
        width: 97%;
    }

    .bannerServicos{
        display: none;
    }

}
