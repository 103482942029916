.infosSobre{
    padding: 70px 35px 30px 35px;
}

.cardsInfos{
    display: flex;
    justify-content: space-around;

    margin: 60px 0px;


    flex-direction: row;
    flex-wrap: wrap;


}

.groupImgSobre{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 50px;
}

/* Responsividade */
@media only screen and (max-width: 1000px){

    .infosSobre{
        padding: 50px 25px 30px 25px;
    }

    .cardsInfos{
        flex-direction: column;
    }

    .groupImg{
        width: 100%;
    }
}