.bannerHome{
    width: 65%;
    padding-top: 100px;
    padding-bottom: 25px;
    padding-left: 30px;
    padding-right: 30px;
}

.headerH3Top {
    margin-top: 30px;
}


/* Responsividade */
@media only screen and (max-width: 1000px) {

    .bannerHome{
        padding-left: 10px;
        padding-right: 10px;

        width: 100%;
        padding-top: 10px;
        padding-bottom: 20px;
    }

    .buttons{
        width: 100%;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}