.backgroundProdutoGas{
    background-image: url('../../../images/background-gerador-gas.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 10px 30px 25px;
    height: 500px;
    margin-top: -2px;
}



/* Responsividade */
@media only screen and (max-width: 1000px){
    .backgroundProdutoGas{
        background-size: contain;
        margin-top: -2px;
    }
}