footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 50px;
    background-color: #F4F4F4;
}

footer > h5{
    padding-top: 28px;
}

/* Responsividade */
@media only screen and (max-width: 1000px){
    footer{
        padding-top: 28px;
        flex-direction: column;
    }
}