

.s1Produto{
    padding: 80px 10px 30px 25px;
    display: flex;
}

.textsS1Produto{
    width: 50%;
    margin-right: 50px;
}

.imgS1Produto{
    height: 300px;
    margin-top: 20px;
}

.divTelProduto{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding-top: 1%;
    padding-bottom: 0.5%;
    background-color: #211F20;
    margin-top: -4px;
}

.textTelProduto{
    padding-top: 6%;
    padding-left: 6%; 
}

.s2Produto{
    background-image: url('../../images/background-azul-escuro.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 40px;
    padding-bottom: 15px;
    text-align: center;
    margin-top: 50px;
}

.s3Produto{
    display: flex;
    justify-content: center;
    margin: 80px 0px;
}

select{
    width: 300px;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
    outline: none;
    font-size: var(--size-h5);
    color: #fff;
    background-color: var(--cor-secundaria);
    border-radius: 5px;
    border: none;
    padding: 10px;
}

.s4Produto{
    padding: 30px 10px 80px 25px;
    display: flex;
    justify-content: space-around;
}


.h5BrancoBgAzulCod{
    font-size: var(--size-h5);
    font-weight: 400;
    color: #fff;
    margin-top: 0;
    background-color: var(--cor-secundaria);
    padding: 40px 30px;
    width: 100px;
    text-align: center;
}

.h5BrancoBgAzulPotEsp{
    font-size: var(--size-h5);
    font-weight: 400;
    color: #fff;
    margin-top: 0;
    background-color: var(--cor-secundaria);
    padding: 15px 30px;
    width: 350px;
    text-align: center;
}

.h5BrancoBgAmareloOrc{
    font-size: var(--size-h5);
    font-weight: 400;
    color: #000;
    margin-top: 0;
    background-color: var(--cor-terciaria);
    padding: 40px 30px;
    width: 150px;
    text-align: center;
}

.tiposEsp{
    display: flex;
    justify-content: space-between;
}

.h5BrancoBgAzulTipo{
    font-size: var(--size-h5);
    font-weight: 400;
    color: #fff;
    margin-top: -15px;
    background-color: var(--cor-secundaria);
    padding: 10px 0px;
    width: 120px;
    text-align: center;
}


/* Responsividade */
@media only screen and (max-width: 1000px){
    .s1Produto{
        margin-top: -100%;
        padding: 0px 10px 30px 25px;
        flex-direction: column;
    }

    .textsS1Produto{
        width: 92%;
        margin-right: 0px;
    }

    .imgS1Produto{
        height: 200px;
    }

    .divTelProduto{
        width: 96%;
        margin-top: -5px;
    }

    .s2Produto{
        padding-top: 40px;
        padding-bottom: 15px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .s3Produto{
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 120px;
    }

    .s4Produto{
        padding: 0px 10px 80px 25px;
        display: flex;
        justify-content: space-between;
        overflow-x: auto;
        white-space: pre-wrap;
        
    }

    .space{
        margin-right: 3%;
    }
    
}