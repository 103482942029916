.backgroundProdutoDiesel{
    background-image: url('../../../images/background-gerador-diesel.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 10px 30px 25px;
    height: 500px;
    margin-top: -2px;
}

.backgroundHeader{
    background-image: url('../../../images/background-azul-escuro.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 10px 10px 20px 25px;
}


/* Responsividade */
@media only screen and (max-width: 1000px){
    .backgroundProdutoDiesel{
        background-size: contain;
        margin-top: -2px;
    }
}