.cardProduto{
    width: 300px;
    background-color: var(--cor-secundaria);
    padding: 50px 25px 50px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.imgProduto{
    padding-left: -3%;
    height: 100%;
    max-height: 250px;
    width: auto;
    overflow-x: hidden;
    max-width: 400px;
}


.linkProduto{
    text-decoration: none;
    color: #000;
}

.text-center {
    text-align: center;
    line-height: 1.5rem;
    padding: 0px 15px 0px 15px;
    max-width: 310px;
}

/* Responsividade */
@media only screen and (max-width: 1000px){

    .cardProduto{
        margin-bottom: 30px;
        width: 100%;
        justify-content: space-evenly;
    }
}