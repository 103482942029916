.cardsProdutos{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 45px;
    margin-bottom: 45px;
}

#produtos {
    padding-left: 7rem;
    padding-right: 7rem;
}

/* Responsividade */
@media only screen and (max-width: 1000px){

    #produtos {
        padding-left: 0;
        padding-right: 0;
    }
}
